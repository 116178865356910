import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Layout from '../components/layout/Layout'
import { Seo as SEO } from '../components/Seo'

import Vision from '../images/about/vission.png'
import Mission from '../images/about/mission.png'

import ImageSlider from '../components/common/ImageSlider'
const VideoSection = React.lazy((() => import('../components/common/VideoSection')))
const AboutSection = React.lazy(() => import('../components/about/AboutSection'))
const ScopeSection = React.lazy(() => import('../components/about/ScopeSection'))
const VisiMisiCard = React.lazy(() => import('../components/about/VisiMisiCard'))
const OurPartnersCard = React.lazy(() => import('../components/about/OurPartnersSection'))
const QualificationsCard = React.lazy(() => import('../components/about/QualificationsSection'))
const RecognitionsCard = React.lazy(() => import('../components/about/RecognitionsSection'))
const LeadershipSection = React.lazy(() => import('../components/about/LeadershipSection'))

const AboutPage = ({ data }) => {
  const { t } = useTranslation()

  const images = data.banner.nodes
  const scopeContents = data.contents.nodes
  const visiMisiContent = [
    {
      image: Vision,
      title: t('Vision'),
      description: t('Vision Text'),
    },
    {
      image: Mission,
      title: t('Mission'),
      description: t('Mission Text'),
    },
  ]
  const partnersContent = data.partners.nodes
  const qualificationsContent = data.qualifications.nodes
  const recognitionContent = data.recognitions.nodes
  const leadershipTeams = data.leaderships.nodes

  return (
    <Layout pathname="/about">
      <ImageSlider images={images} autoplay />
      <AboutSection
        title={t('About Section')}
        description={t('About Section Text')}
      />
      <ScopeSection scopeContents={scopeContents}/>
      <VisiMisiCard visimisis={visiMisiContent} />
      <VideoSection
        videoSrcURL="https://drive.google.com/file/d/1qmcdorhATXJJzrV8pTCfaQCsQ2R5TmW6/preview"
        videoTitle="YouTube video player"
        className="rounded-lg shadow-md"
      />
      <OurPartnersCard partners={partnersContent} />
      <QualificationsCard qualifications={qualificationsContent} />
      <RecognitionsCard recognitions={recognitionContent} />
      <LeadershipSection leadershipTeams={leadershipTeams} />
    </Layout>
  )
}

export default AboutPage

export const Head = () => {
  return (
    <SEO
      title={'Bifarma Adiluhung | About'}
      description={
        'Learn about Bifarma Adiluhung, a leading biotechnology company committed to improving health worldwide. Discover our mission, subsidiaries, and groundbreaking research in the biopharmaceutical field.'
      }
    />
  )
}

export const query = graphql`
  query ($language: String!, $locale: String) {
    locales: allLocale(
      filter: { ns: { in: ["common", "about"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    banner: allContentfulHomeBanner(sort: {fields: slideOrder, order: ASC}, filter: {node_locale: {eq: "en-US"}}) {
      nodes{
        title
        image {
          gatsbyImageData
        }
      }
    }
    contents: allContentfulScopeOfBusiness(
      filter: {node_locale: {glob: $locale}}) {
      nodes {
        image {
          gatsbyImageData
        }
        title
      }
    }
    recognitions: allContentfulRecognitions(
      filter: { node_locale: { glob: $locale } }
    ) {
      nodes {
        title
        image {
          gatsbyImageData
        }
      }
    }
    partners: allContentfulOurPartners {
      nodes {
        title
        image {
          gatsbyImageData
        }
      }
    }
    qualifications: allContentfulQualifications(
      filter: { node_locale: { glob: $locale } }
    ) {
      nodes {
        image {
          gatsbyImageData
          title
        }
      }
    }
    leaderships: allContentfulLeadershipTeam(
      sort: { fields: order }
      filter: { node_locale: { glob: $locale } }
    ) {
      nodes {
        image {
          gatsbyImageData
        }
        fullName
        position
      }
    }
  }
`
